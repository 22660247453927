import React from 'react'
import TemarioNutricion from './TemarioNutricion'
import TemarioEsteticaTerapeutica from './TemarioEsteticaTerapeutica';
import TemarioGestionEnfermeria from './TemarioGestionEnfermeria';
import TemarioPediatricos from './TemarioPediatricos';
import TemarioMicrobiota from './TemarioMicrobiota';
import TemarioRemodelacionFacial from './TemarioRemodelacionFacial';
import TemarioEnfermeriaOncologica from './TemarioEnfermeriaOncologica';
import TemarioCuidadosPaliativos from './TemarioCuidadosPaliativos';
import TemariosGestionEmpresasSalud from './TemariosGestionEmpresasSalud';
import TemarioNutricionDeportiva from './TemarioNutricionDeportiva';
import TemarioToxinaBotulinica from './TemarioToxinaBotulinica';
import TemarioToxicologia from './TemarioToxicologia';
import TemarioPrehospitalario from './TemarioPrehospitalario';
import TemarioPeelingCosmiatras from './TemarioPeelingCosmiatras';
import TemarioPeelingDermatologos from './TemarioPeelingDermatologos';
import TemarioRCP from './TemarioRCP';
import TemarioInmunizaciones from './TemarioInmunizaciones';
import TemarioVidaSaludable from './TemarioVidaSaludable';
import TemarioNutricionDiabetes from './TemarioNutricionDiabetes';
import TemarioSkincare from './TemarioSkincare';
import TemarioEnfermeriaNeurologica from './TemarioEnfermeriaNeurologica';
import TemarioTCA from './TemarioTCA';
import TemarioTerapiaIntensivaPediatrica from './TemarioTerapiaIntensivaPediatrica';

const Temarios = ({ index }) => {
    const temarios = [
        <TemarioNutricion />,
        <TemarioEsteticaTerapeutica />,
        <TemarioGestionEnfermeria />,
        <TemarioPediatricos />,
        <TemarioMicrobiota />,
        <TemarioRemodelacionFacial />,
        <TemarioEnfermeriaOncologica />,
        <TemarioCuidadosPaliativos />,
        <TemariosGestionEmpresasSalud />,
        <TemarioNutricionDeportiva />,
        <TemarioToxinaBotulinica />,
        <TemarioToxicologia />,
        <TemarioPrehospitalario />,
        <TemarioPeelingCosmiatras />,
        <TemarioPeelingDermatologos />,
        <TemarioRCP />,
        <TemarioInmunizaciones />,
        <TemarioVidaSaludable />,
        <TemarioNutricionDiabetes />,
        <TemarioSkincare />,
        <TemarioEnfermeriaNeurologica />,
        <TemarioTCA />,
        <TemarioTerapiaIntensivaPediatrica />,
    ];

    return (
        <div>
            {temarios[index]}
        </div>
    );
}

export default Temarios