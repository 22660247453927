import { useParams } from 'react-router-dom';
import CuidadosPediatricosRespiratorios from '../CuidadosPediatricosRespiratorios';
import NutricionMicrobiota from '../NutricionMicrobiota';
import RemodelacionFacial from '../RemodelacionFacial';
import EnfermeriaOncologica from '../EnfermeriaOncologica';
import PaginaError from '../../../PaginaError';
import EnfermeriaCuidadosPaliativos from '../EnfermeriaCuidadosPaliativos';
import GestionServiciosEmpresasSalud from '../GestionServiciosEmpresasSalud';
import NutricionDeportiva from '../NutricionDeportiva';
import ToxinaBotulinica from '../ToxinaBotulinica';
import UrgenciasToxicologia from '../UrgenciasToxicologia';
import NutricionDiabetes from '../NutricionDiabetes';
import Skincare from '../Skincare';
import EnfermeriaNeurologica from '../EnfermeriaNeurologica';
import TCA from '../TCA';
import TerapiaIntensivaPediatrica from '../TerapiaIntensivaPediatrica';

const DiplomaturaRedirect = () => {
    const { curso } = useParams();
    let serviceComponent;
    switch (curso) {
        case 'cuidados-pediatricos-respiratorios':
            serviceComponent = <CuidadosPediatricosRespiratorios />
            break;
        case 'nutricion-y-microbiota':
            serviceComponent = <NutricionMicrobiota />
            break;
        case 'remodelacion-facial':
            serviceComponent = <RemodelacionFacial />
            break;
        case 'enfermeria-oncologica':
            serviceComponent = <EnfermeriaOncologica />
            break;
        case 'enfermeria-cuidados-paliativos-en-pacientes-adultos-y-pediatricos':
            serviceComponent = <EnfermeriaCuidadosPaliativos />
            break;
        case 'gestion-de-servicios-y-empresas-de-salud':
            serviceComponent = <GestionServiciosEmpresasSalud />
            break;
        case 'nutricion-deportiva-de-alto-rendimiento':
            serviceComponent = <NutricionDeportiva />
            break;
        case 'toxina-botulinica':
            serviceComponent = <ToxinaBotulinica />
            break;
        case 'urgencias-en-toxicologia':
            serviceComponent = <UrgenciasToxicologia />
            break;
        case 'nutricion-y-diabetes-tipo-2':
            serviceComponent = <NutricionDiabetes />
            break;
        case 'skincare':
            serviceComponent = <Skincare />
            break;
        case 'enfermeria-neurologica':
            serviceComponent = <EnfermeriaNeurologica />
            break;
        case 'tca-abordaje-integral-y-enfoque-practico':
            serviceComponent = <TCA />
            break;
        case 'terapia-intensiva-pediatrica':
            serviceComponent = <TerapiaIntensivaPediatrica />
            break;
        default:
            serviceComponent = <PaginaError />
            break;
    }

    return serviceComponent
};

export default DiplomaturaRedirect;