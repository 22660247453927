import React, { useContext, useEffect, useRef } from 'react'
import SideNav from '../Navbars/SideNav'
import NavbarEducativa from '../Navbars/NavbarEducativa'
import whatsappIcon from '../multimedia/imagenes/whatsapp.png'
import transicionEscalera from '../Transiciones/transicionEscalera'
import { Link } from 'react-router-dom'
import transicionBottomTop from '../Transiciones/transicionBottomTop'
import FooterEducativo from './FooterEducativo'
import { context } from '../Context/Context'


const ListaCursos = () => {
    const isMountedRef = useRef(false);
    const { cursos } = useContext(context)
    const link = 'https://api.whatsapp.com/send?phone=541123879118&text=Hola!%20Quiero%20informaci%C3%B3n%20sobre%20los%20cursos'

    useEffect(()=>{
        if (!isMountedRef.current) {
            window.scroll(0, 0);
            isMountedRef.current = true;
        }
    },[])
    return (
        <main className='cursos-container section-md'>
            <SideNav />
            <NavbarEducativa />
            <a href={link} target='_blank'>
                <img className='whatsapp-icon' src={whatsappIcon} alt="whatsappIcon" />
            </a>
            <div className="container-xxl pt-5 mt-5">
                <div className="py-5" id='hid-1400'></div>
                <h5 className='fs-5 mb-5 path'><Link to="/educativo/">Inicio</Link> &gt; Cursos</h5>
                <h4 className='fs-3 fw-semibold'>Nuestros cursos</h4>
                <div className="row d-flex gap-4 py-5 justify-content-center">
                    {
                        cursos
                            .filter((curso) => curso.tipoCapacitacion === "curso")
                            .map((curso, index) => {
                            return (
                                <div className="curso-preview" key={index}>
                                <Link to={curso.link}><img src={curso.imagen} className='w-100' alt={curso.titulo} /></Link>
                                {/* <h6 className='poppins px-3'>{curso.director}</h6> */}
                                <h6 className='poppins px-3 my-0 pb-2'><Link to={curso.link}>{curso.especialidad}</Link></h6>
                                <p className='poppins px-3 my-0 pb-2'>{curso.titulo}</p>
                                <p className='poppins ms-3 px-3 my-0 curso-date d-flex gap-2 align-items-center rounded-4'>
                                    <svg width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <path d="M4 5m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z" />
                                    <path d="M16 3l0 4" />
                                    <path d="M8 3l0 4" />
                                    <path d="M4 11l16 0" />
                                    <path d="M8 15h2v2h-2z" />
                                    </svg>
                                    {curso.fecha}
                                </p>
                                <p className='poppins px-3 my-0 pb-4'>{curso.descripcion}</p>
                                <button className='mb-3'><Link to={curso.link}>+ INFO</Link></button>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <FooterEducativo />
        </main>
    )
}

export default window.innerWidth >= 1000 ? transicionEscalera(ListaCursos) : transicionBottomTop(ListaCursos);
