import './Componentes/CSS/SASS/App.scss'
import { BrowserRouter } from 'react-router-dom';
import ContextProvider from './Componentes/Context/Context.jsx';
import AnimatedRoutes from './AnimatedRoutes';

function App() {
  return (
    <>
      <ContextProvider>
        <BrowserRouter>
          <AnimatedRoutes />
        </BrowserRouter>
      </ContextProvider>
    </>
  );
}

export default App;
