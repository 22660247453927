import React, { useEffect, useRef, useState } from 'react'
import SideNav from '../../Navbars/SideNav.jsx'
import NavbarEducativa from '../../Navbars/NavbarEducativa.jsx'
import transicion from '../../Transiciones/transicionEscalera.jsx'
import portadaCurso from '../../multimedia/imagenes/educativo/cursos/portada/terapia-intensiva-pediatrica.png'
import whatsappIcon from '../../multimedia/imagenes/whatsapp.png'
import { Link } from 'react-router-dom'
import UBA from '../../../Componentes/multimedia/imagenes/Logos - Empresas/UBA.png'
import FISP from '../../../Componentes/multimedia/imagenes/logo-iniciales-oscuro.png'
import Director from '../../../Componentes/multimedia/imagenes/fundacion/staff-academico/Pierini.jpg'
import Temarios from './Temarios/Temarios.jsx'
import Brochure from '../../../Componentes/Educativo/Cursos/Brochures/Brochure_TerapiaPediatrica.pdf'
import FooterEducativo from '../FooterEducativo.jsx'

const TerapiaIntensivaPediatrica = () => {
    const isMountedRef = useRef(false);
    const [options,setOptions] = useState([])
    const link = 'https://api.whatsapp.com/send?phone=5491123879118&text=Hola!%20Quisiera%20saber%20precio%20y%20forma%20de%20pago%20de%20la%20Diplomatura%20para%20Enfermería%20en%20terapia%20intensiva%20pediátrica%0A'
    const linkInscripcion = '/educativo/pre-inscripcion/terapia-intensiva-pediatrica'
    
    useEffect(()=>{
        if (!isMountedRef.current) {
            window.scroll(0, 0);
            isMountedRef.current = true;
        }
        const optionsDivs = Array.from(document.querySelectorAll('.option'))
        setOptions(optionsDivs)
    },[])
    const optionsContent = [
        {
            contenido: (
                <div>
                    <p className="fs-5 fw-normal">
                    La atención de los pacientes internados en las Unidades de terapia intensiva pediátricas requieren que el personal logre actualización en su formación constante de sus equipos de salud para lograr una atención con resultados favorables, integrales y seguros, entre las afecciones más prevalentes donde la incidencia en la morbilidad y mortalidad en los primeros años de vida son más frecuentes y requieren intervención inmediata en el sistema hospitalaria
                    <br /><br />
                        Título FISP y Facultad de Medicina de la UBA
                    </p>
                    <div className='mt-4 d-flex gap-5 w-50'>
                        <img src={FISP} className='w-50' alt="Logo FISP" />
                        <img src={UBA} className='w-50' alt="Logo UBA" />
                    </div>
                </div>
            ),
        },
        {
            contenido: (
                "Mg. Pierini Stella Maris"
            ),
            imagen: Director,
        },
            {
            contenido: (
                <p className="fs-5 fw-normal">
                    Modalidad: Virtual
                </p>
            ),
        },{
            contenido: (
                <div className='d-flex align-items-start flex-column gap-3'>
                    <a href={Brochure} download>Descargar Brochure</a>
                    <p>
                        <Temarios index='22' />
                    </p>
                </div>
            ),
        }
    ];
    const [infoContent,setInfoContent] = useState(optionsContent[0])
    const toggleActive = (e)=>{
        options.map((option,index)=>{
            if(option==e){
                e.classList.add('active');
                e.classList.remove('inactive')
                setInfoContent(optionsContent[index])
            } else{
                option.classList.add('inactive')
                option.classList.remove('active')
            }
        })
    }

    return (
        <main className='curso-detalle-container section-big'>
            <NavbarEducativa />
            <a href={link} target='_blank'>
                <img className='whatsapp-icon' src={whatsappIcon} alt="whatsappIcon" />
            </a>
            <div className="container-xxl pt-5 mt-5">
                <div className="py-5" id='hid-1400'></div>
                <h5 className='fs-5 mb-5 path'><Link to="/educativo/">Inicio</Link> &gt; <Link to="/educativo/lista-diplomaturas/">Diplomaturas</Link> &gt; Enfermería en terapia intensiva pediátrica</h5>
            </div>
            <div className="container-fluid curso-main-details">
                <div className="container-xxl">
                    <div className="row pt-5 position-relative">
                        <div className="curso-small-line col-12 col-md-10 col-lg-8 d-flex justify-content-between me-md-5 mx-0 px-sm-3 px-0">
                            <div className="profesor d-flex align-items-center gap-4">
                                <div className='profesor-image'>
                                    <img src={optionsContent[1].imagen} className="w-100 h-100 rounded-5" alt={optionsContent[1].contenido} />
                                </div>
                                <div className='d-flex flex-column justify-content-center'>
                                    <p className='my-0'>Director/a del curso</p>
                                    <span className='my-0 text-light'>{optionsContent[1].contenido}</span>
                                </div>
                            </div>
                            <div className="curso-category d-flex align-items-center gap-2">
                                <svg width="50" height="50" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#FFFFFF" fill="none">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <path d="M9 4h6a2 2 0 0 1 2 2v14l-5 -3l-5 3v-14a2 2 0 0 1 2 -2" />
                                </svg>
                                <div className="d-flex g flex-column justify-content-center me-5">
                                    <Link to="/educativo/categoria/enfermeria">
                                        <p className="my-0">Categoria</p>
                                        <span className='my-0 text-light'>Enfermería</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-10 col-lg-8 mt-4 me-5">
                            <h2 className='fs-4 fw-semibold text-light'>Diplomatura para Enfermería en terapia intensiva pediátrica</h2>
                        </div>
                        <div className="curso-brief-details d-flex justify-content-between col-12 col-md-10 col-lg-8 mt-4 me-md-5 mx-0 px-sm-3 px-0">
                            <div className='text-light d-flex gap-2 poppins fw-light'>
                                <svg width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#FFFFFF" fill="none">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                                    <path d="M12 12h3.5" />
                                    <path d="M12 7v5" />
                                </svg>
                                5 meses
                            </div>
                            <div className='text-light d-flex gap-2 poppins fw-light'>
                                <svg width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <path d="M6.5 17h11" />
                                    <path d="M6 20v-2a6 6 0 1 1 12 0v2a1 1 0 0 1 -1 1h-10a1 1 0 0 1 -1 -1z" />
                                    <path d="M6 4v2a6 6 0 1 0 12 0v-2a1 1 0 0 0 -1 -1h-10a1 1 0 0 0 -1 1z" />
                                </svg>
                                120 horas
                            </div>
                            <div className='text-light d-flex gap-2 poppins fw-light'>
                                <svg width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <path d="M5 4m0 1a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v14a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1z" />
                                    <path d="M9 4m0 1a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v14a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1z" />
                                    <path d="M5 8h4" />
                                    <path d="M9 16h4" />
                                    <path d="M13.803 4.56l2.184 -.53c.562 -.135 1.133 .19 1.282 .732l3.695 13.418a1.02 1.02 0 0 1 -.634 1.219l-.133 .041l-2.184 .53c-.562 .135 -1.133 -.19 -1.282 -.732l-3.695 -13.418a1.02 1.02 0 0 1 .634 -1.219l.133 -.041z" />
                                    <path d="M14 9l4 -1" />
                                    <path d="M16 16l3.923 -.98" />
                                </svg>
                                Modalidad Virtual
                            </div>
                        </div>
                        <div className="p-0 m-0 col-sm-11 col-12 col-xl-3 curso-image-container box-shadow">
                            <div className='p-0 m-0 col-xl-12 col-6' id='curso-image-div'>
                                <img className='w-100' src={portadaCurso} alt="Diplomatura para Enfermería en terapia intensiva pediátrica" />
                            </div>
                            <div className='col-xl-12 col-6 d-flex align-items-center flex-column'>
                                {/* <button className='my-3'><a href={link} target='_blank'>Más información</a></button> */}
                                <button className='my-3'><Link to={linkInscripcion}>Precios y formas de pagos</Link></button>
                            </div>
                        </div>
                    </div>
                    <div className="dropdowns col-sm-11 col-12 col-xl-8 py-5 mt-0 mt-sm-5">
                            <div className="dropdown rounded-2 px-5 position-relative">
                                <h5 className='fs-5'>Destinatarios</h5>
                                <svg width="12" height="12" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <path d="M19.007 3a3 3 0 0 1 2.828 3.94l-.068 .185l-.062 .126l-7.09 12.233a3 3 0 0 1 -5.137 .19l-.103 -.173l-7.1 -12.25l-.061 -.125a3 3 0 0 1 2.625 -4.125l.058 -.001l.06 .002l.043 -.002h14.007z" strokeWidth="0" fill="currentColor" />
                                </svg>
                                <p className='fw-normal poppins'>Enfermeros y Licencias en Enfermeria.</p>
                            </div>
                            <div className="dropdown rounded-2 px-5 position-relative">
                                <h5 className='fs-5'>Ciclo lectivo</h5>
                                <svg width="12" height="12" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <path d="M19.007 3a3 3 0 0 1 2.828 3.94l-.068 .185l-.062 .126l-7.09 12.233a3 3 0 0 1 -5.137 .19l-.103 -.173l-7.1 -12.25l-.061 -.125a3 3 0 0 1 2.625 -4.125l.058 -.001l.06 .002l.043 -.002h14.007z" strokeWidth="0" fill="currentColor" />
                                </svg>
                                <p className='fw-normal d-flex flex-column'>
                                    <span className='fw-normal poppins'>Inicio: 15 de agosto del 2024</span>
                                    <span className='fw-normal poppins'>Finalización: 05 de diciembre 2024</span>
                                    <span className='fw-normal poppins'>Días y horarios: Jueves de 20.00 a 22.00hs</span>
                                    <span className='fw-normal poppins'>Duración del curso: 5 meses</span>
                                </p>
                            </div>
                            <div className="dropdown rounded-2 px-5 position-relative">
                                <h5 className='fs-5'>Distribución horaria</h5>
                                <svg width="12" height="12" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <path d="M19.007 3a3 3 0 0 1 2.828 3.94l-.068 .185l-.062 .126l-7.09 12.233a3 3 0 0 1 -5.137 .19l-.103 -.173l-7.1 -12.25l-.061 -.125a3 3 0 0 1 2.625 -4.125l.058 -.001l.06 .002l.043 -.002h14.007z" strokeWidth="0" fill="currentColor" />
                                </svg>
                                <p className='fw-normal d-flex flex-column'>
                                    <span className='fw-normal poppins ps-1'>120 horas cátedra</span>
                                    <span className='fw-normal poppins ps-1'>- Compuesto por exámenes, bibliografía, trabajos prácticos y contenido en plataforma</span>
                                    <span className='fw-normal poppins ps-1'>- Clases cada 15 días + clases asincrónicas</span>
                                </p>
                            </div>
                            <div className="dropdown rounded-2 px-5 position-relative">
                                <h5 className='fs-5'>Requisitos</h5>
                                <svg width="12" height="12" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <path d="M19.007 3a3 3 0 0 1 2.828 3.94l-.068 .185l-.062 .126l-7.09 12.233a3 3 0 0 1 -5.137 .19l-.103 -.173l-7.1 -12.25l-.061 -.125a3 3 0 0 1 2.625 -4.125l.058 -.001l.06 .002l.043 -.002h14.007z" strokeWidth="0" fill="currentColor" />
                                </svg>
                                <p className='fw-normal d-flex flex-column'>
                                    <span className="fw-normal poppins">a) Ser graduado de la Universidad de Buenos Aires con título de grado correspondiente a la carrera de Enfermería, o</span>
                                    <span className="fw-normal poppins">b) Ser graduado de otras universidades argentinas con título de grado correspondiente a la carrera de Enfermería, o</span>
                                    <span className="fw-normal poppins">c) Ser graduado de universidades extranjeras con título de grado correspondiente a la carrera de Enfermería.</span>
                                </p>
                            </div>
                        </div>
                        <div className="curso-more-info ps-2 col-xl-8 col-sm-11 col-12 row d-flex">
                            <div className="option active col text-center fs-5" onMouseOver={(e)=>toggleActive(e.target)}>Descripción</div>
                            <div className="option inactive col text-center fs-5" onMouseOver={(e)=>toggleActive(e.target)}>Director/a del curso</div>
                            <div className="option inactive col text-center fs-5" onMouseOver={(e)=>toggleActive(e.target)}>Modalidad</div>
                            <div className="option inactive col text-center fs-5" onMouseOver={(e)=>toggleActive(e.target)}>Temario</div>
                        </div>
                        <div className="info-area col-xl-8 col-sm-11 col-12 py-4 d-flex flex-column gap-2">
                            {infoContent.contenido}
                            {infoContent.imagen && <img className='w-25 rounded-3' src={infoContent.imagen} alt={infoContent.contenido} />}
                        </div>
                    </div>
                    <div className="py-5"></div>
                <FooterEducativo />
            </div>
        </main>
    )
}

export default transicion(TerapiaIntensivaPediatrica)