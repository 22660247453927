import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import NavbarEducativa from '../../../Navbars/NavbarEducativa'
import FooterEducativo from '../../FooterEducativo'
import PricingCurso from './PricingCurso'
import transicionEscalera from '../../../Transiciones/transicionEscalera'

const PreInscripcion = () => {

    const isMountedRef = useRef(false);
    useEffect(()=>{
        if (!isMountedRef.current) {
            window.scroll(0, 0);
            isMountedRef.current = true;
        }
    },[])

    const { curso } = useParams();
    const [pricingCurso, setPricingCurso] = useState()
    const navigate = useNavigate()

    useEffect(()=>{
        switch(curso){
            //TEMPORADA DE REBAJAS
            //Posgrados
            case 'nutricion':
                setPricingCurso(<PricingCurso cursoId={544} nombreCurso={"Posgrado en Nutrición"} cuposLlenos={true} incluyeMatricula={true} incluyeExtranjero={true} cuotasFraccionadas={true} matriculaLocal={"170.000"} matriculaExtranjero={"280"} primerasCuotasLocal={5} ultimasCuotasLocal={5} precioPrimerasCuotasLocal={"170.000"} precioUltimasCuotasLocal={"255.000"} cuotasExtranjero={10} precioCuotasExtranjero={280} />)
                break;
            case 'dermatologia-estetica-terapeutica':
                setPricingCurso(<PricingCurso cursoId={573} nombreCurso={"Posgrado en Dermatología Estética Terapéutica"} cuposLlenos={true} incluyeMatricula={true} incluyeExtranjero={true} cuotasFraccionadas={true} matriculaLocal={"188.500"} matriculaExtranjero={"320"} primerasCuotasLocal={5} ultimasCuotasLocal={5} precioPrimerasCuotasLocal={"188.500"} precioUltimasCuotasLocal={"282.000"} cuotasExtranjero={10} precioCuotasExtranjero={320} />)
                break;
            case 'gestion-y-administracion-de-servicio-de-enfermeria':
                setPricingCurso(<PricingCurso cursoId={595} nombreCurso={"Posgrado de Gestión y Administración de servicios en Enfermería"} cuposLlenos={true} incluyeMatricula={true} incluyeExtranjero={true} cuotasFraccionadas={true} matriculaLocal={"67.000"} matriculaExtranjero={"120"} primerasCuotasLocal={5} ultimasCuotasLocal={5} precioPrimerasCuotasLocal={"67.000"} precioUltimasCuotasLocal={"102.000"} cuotasExtranjero={10} precioCuotasExtranjero={"120"} />)
                break;
                //Diplomaturas
            case 'cuidados-pediatricos-respiratorios':
                setPricingCurso(<PricingCurso cursoId={597} nombreCurso={"Diplomatura en Cuidados Pediátricos Respiratorios"} nuevoFormato={true} cuposLlenos={true} incluyeExtranjero={true} cuotasFraccionadas={false} primerasCuotasLocal={"9"} precioPrimerasCuotasLocal={"55.000"} cuotasExtranjero={6} precioCuotasExtranjero={"90"} />)
                break;
            case 'nutricion-y-microbiota':
                setPricingCurso(<PricingCurso cursoId={599} nombreCurso={"Diplomatura en Nutrición y Microbiota"} nuevoFormato={true} incluyeExtranjero={true} cuotasFraccionadas={false} primerasCuotasLocal={9} precioPrimerasCuotasLocal={"102.000"} cuotasExtranjero={6} precioCuotasExtranjero={"200"} />)
                // setPricingCurso(<PricingCurso cursoId={599} incluyeMatricula={true} incluyeExtranjero={true} cuotasFraccionadas={false} matriculaLocal={"62.000"} matriculaExtranjero={"88"} primerasCuotasLocal={5} precioPrimerasCuotasLocal={"153.000"} cuotasExtranjero={6} precioCuotasExtranjero={"220"} />)
                break;
            case 'remodelacion-facial':
                setPricingCurso(<PricingCurso cursoId={600} nombreCurso={"Diplomatura en Remodelación Facial"} nuevoFormato={true} incluyeExtranjero={true} cuotasFraccionadas={false} primerasCuotasLocal={9} precioPrimerasCuotasLocal={"113.333,33"} cuotasExtranjero={6} precioCuotasExtranjero={"280"} />)
                // setPricingCurso(<PricingCurso cursoId={600} incluyeMatricula={true} incluyeExtranjero={true} cuotasFraccionadas={false} matriculaLocal={"68.000"} matriculaExtranjero={"112"} primerasCuotasLocal={5} precioPrimerasCuotasLocal={"170.000"} cuotasExtranjero={6} precioCuotasExtranjero={"280"} />)
                break;
            case 'enfermeria-oncologica':
                setPricingCurso(<PricingCurso cursoId={611} nombreCurso={"Diplomatura en Enfermería Oncológica"} nuevoFormato={true} incluyeExtranjero={true} cuotasFraccionadas={false} primerasCuotasLocal={9} precioPrimerasCuotasLocal={"43.333,33"} cuotasExtranjero={6} precioCuotasExtranjero={"90"} />)
                // setPricingCurso(<PricingCurso cursoId={611} incluyeMatricula={true} incluyeExtranjero={true} cuotasFraccionadas={false} matriculaLocal={"26.000"} matriculaExtranjero={"36"} primerasCuotasLocal={5} precioPrimerasCuotasLocal={"65.000"} cuotasExtranjero={6} precioCuotasExtranjero={"90"} />)
                break;
            case 'enfermeria-cuidados-paliativos-en-pacientes-adultos-y-pediatricos':
                setPricingCurso(<PricingCurso cursoId={612} nombreCurso={"Diplomatura en Enfermería: Cuidados Paliativos"} nuevoFormato={true} cuposLlenos={true} incluyeExtranjero={true} cuotasFraccionadas={false} matriculaLocal={"55.000"} matriculaExtranjero={"90"} primerasCuotasLocal={5} precioPrimerasCuotasLocal={"55.000"} cuotasExtranjero={6} precioCuotasExtranjero={"90"} />)
                break;
            case 'gestion-de-servicios-y-empresas-de-salud':
                setPricingCurso(<PricingCurso cursoId={615} nombreCurso={"Diplomatura en Gestión de Servicios y Empresas de Salud"} nuevoFormato={true} incluyeExtranjero={true} cuotasFraccionadas={false} primerasCuotasLocal={9} precioPrimerasCuotasLocal={"73.333,33"} cuotasExtranjero={6} precioCuotasExtranjero={"160"} />)
                // setPricingCurso(<PricingCurso cursoId={615} incluyeMatricula={true} incluyeExtranjero={true} cuotasFraccionadas={false} matriculaLocal={"40.000"} matriculaExtranjero={"72"} primerasCuotasLocal={5} precioPrimerasCuotasLocal={"100.000"} cuotasExtranjero={6} precioCuotasExtranjero={"180"} />)
                break;
            case 'nutricion-deportiva-de-alto-rendimiento':
                setPricingCurso(<PricingCurso cursoId={616} nombreCurso={"Diplomatura en Nutrición Deportiva de Alto Rendimiento"} nuevoFormato={true} incluyeExtranjero={true} cuotasFraccionadas={false} primerasCuotasLocal={9} precioPrimerasCuotasLocal={"80.000"} cuotasExtranjero={6} precioCuotasExtranjero={"160"} />)
                // setPricingCurso(<PricingCurso cursoId={616} incluyeMatricula={true} incluyeExtranjero={true} cuotasFraccionadas={false} matriculaLocal={"48.000"} matriculaExtranjero={"72"} primerasCuotasLocal={5} precioPrimerasCuotasLocal={"120.000"} cuotasExtranjero={6} precioCuotasExtranjero={"180"} />)
                    break;
            case 'toxina-botulinica':
                setPricingCurso(<PricingCurso cursoId={620} nombreCurso={"Diplomatura Avanzada en Toxina Botulínica: Aplicaciones Clínicas y Estéticas"} nuevoFormato={true} cuposLlenos={true} incluyeExtranjero={true} cuotasFraccionadas={false} matriculaLocal={"160.000"} matriculaExtranjero={"280"} primerasCuotasLocal={5} precioPrimerasCuotasLocal={"160.000"} cuotasExtranjero={6} precioCuotasExtranjero={"280"} />)
                break;
            case 'urgencias-en-toxicologia':
                setPricingCurso(<PricingCurso cursoId={621} nombreCurso={"Diplomatura de Urgencias en Toxicología"} nuevoFormato={true} incluyeExtranjero={true} cuotasFraccionadas={false} primerasCuotasLocal={9} precioPrimerasCuotasLocal={"66.666,67"} cuotasExtranjero={6} precioCuotasExtranjero={"160"} />)
                // setPricingCurso(<PricingCurso cursoId={621} incluyeMatricula={true} incluyeExtranjero={true} cuotasFraccionadas={false} matriculaLocal={"40.000"} matriculaExtranjero={"72"} primerasCuotasLocal={5} precioPrimerasCuotasLocal={"100.000"} cuotasExtranjero={6} precioCuotasExtranjero={"180"} />)
                break;
            case 'terapia-intensiva-pediatrica':
                setPricingCurso(<PricingCurso cursoId={1051} nombreCurso={"Diplomatura en Enfermería: Terapia Intensiva pediátrica"} nuevoFormato={true} incluyeExtranjero={true} cuotasFraccionadas={false} primerasCuotasLocal={9} precioPrimerasCuotasLocal={"43.333,33"} cuotasExtranjero={6} precioCuotasExtranjero={"90"} />)
                // setPricingCurso(<PricingCurso cursoId={1051} incluyeMatricula={true} incluyeExtranjero={true} cuotasFraccionadas={false} matriculaLocal={"26.000"} matriculaExtranjero={"36"} primerasCuotasLocal={5} precioPrimerasCuotasLocal={"65.000"} cuotasExtranjero={6} precioCuotasExtranjero={"90"} />)
                break;
            case 'enfermeria-neurologica':
                setPricingCurso(<PricingCurso cursoId={1050} nombreCurso={"Diplomatura en Enfermería Neurológica"} nuevoFormato={true} incluyeExtranjero={true} cuotasFraccionadas={false} primerasCuotasLocal={9} precioPrimerasCuotasLocal={"43.333,33"} cuotasExtranjero={6} precioCuotasExtranjero={"90"} />)
                // setPricingCurso(<PricingCurso cursoId={1050} incluyeMatricula={true} incluyeExtranjero={true} cuotasFraccionadas={false} matriculaLocal={"26.000"} matriculaExtranjero={"36"} primerasCuotasLocal={5} precioPrimerasCuotasLocal={"65.000"} cuotasExtranjero={6} precioCuotasExtranjero={"90"} />)
                break;
            case 'nutricion-y-diabetes-tipo-2':
                setPricingCurso(<PricingCurso cursoId={1049} nombreCurso={"Diplomatura en Nutrición y Diabetes tipo 2"} nuevoFormato={true} incluyeExtranjero={true} cuotasFraccionadas={false} primerasCuotasLocal={9} precioPrimerasCuotasLocal={"73.333,33"} cuotasExtranjero={6} precioCuotasExtranjero={"150"} />)
                // setPricingCurso(<PricingCurso cursoId={1049} incluyeMatricula={true} incluyeExtranjero={true} cuotasFraccionadas={false} matriculaLocal={"44.000"} matriculaExtranjero={"60"} primerasCuotasLocal={5} precioPrimerasCuotasLocal={"110.000"} cuotasExtranjero={6} precioCuotasExtranjero={"150"} />)
                break;
            case 'trastornos-de-conducta-alimentaria':
                setPricingCurso(<PricingCurso cursoId={1048} nombreCurso={"Diplomatura en Trastornos de Conducta Alimentaria (TCA)"} nuevoFormato={true} incluyeExtranjero={true} cuotasFraccionadas={false} primerasCuotasLocal={9} precioPrimerasCuotasLocal={"50.000"} cuotasExtranjero={6} precioCuotasExtranjero={"100"} />)
                // setPricingCurso(<PricingCurso cursoId={1048} incluyeMatricula={true} incluyeExtranjero={true} cuotasFraccionadas={false} matriculaLocal={"30.000"} matriculaExtranjero={"40"} primerasCuotasLocal={5} precioPrimerasCuotasLocal={"75.000"} cuotasExtranjero={6} precioCuotasExtranjero={"100"} />)
                break;
            case 'especializacion-en-skincare':
                setPricingCurso(<PricingCurso cursoId={1047} nombreCurso={"Diplomatura Especializada en Skincare"} nuevoFormato={true} incluyeExtranjero={true} cuotasFraccionadas={false} primerasCuotasLocal={9} precioPrimerasCuotasLocal={"50.000"} cuotasExtranjero={6} precioCuotasExtranjero={"110"} />)
                // setPricingCurso(<PricingCurso cursoId={1047} incluyeMatricula={true} incluyeExtranjero={true} cuotasFraccionadas={false} matriculaLocal={"30.000"} matriculaExtranjero={"44"} primerasCuotasLocal={5} precioPrimerasCuotasLocal={"75.000"} cuotasExtranjero={6} precioCuotasExtranjero={"110"} />)
                break;
                //Cursos
            case 'operador-prehospitalario-de-emergencias':
                setPricingCurso(<PricingCurso cursoId={31} nombreCurso={"Curso de Operador prehospitalario de emergencias"} cuposLlenos={true} incluyeMatricula={true} incluyeExtranjero={false} cuotasFraccionadas={false} matriculaLocal={"75.000"} primerasCuotasLocal={8} precioPrimerasCuotasLocal={"75.000"} />)
                break;  
            case 'programa-ampliado-de-inmunizaciones':
                setPricingCurso(<PricingCurso cursoId={364} nombreCurso={"Programa Ampliado de Inmunizaciones"} incluyeMatricula={false} incluyeExtranjero={true} primerasCuotasLocal={2} cuotasExtranjero={2} precioPrimerasCuotasLocal={"30.000"} precioCuotasExtranjero={25} />)
                break;
            default:
                setPricingCurso(null)
                navigate('/educativo')
        }
    },[curso])
    return (
        <main className='pricing-bg'>
            <NavbarEducativa />
            <div className="container-xxl section-big pricing-container">
                <div className="mt-5 pt-5 d-flex flex-column justify-content-center align-items-center">
                    <div className="col-lg-8 col-12">
                        {pricingCurso}
                    </div>
                </div>
            </div>
            <FooterEducativo />
        </main>
    )
}

export default transicionEscalera(PreInscripcion)