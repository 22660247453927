import React, { useState } from 'react'
import Axios from "axios";
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';

const Modal = ({linkCurso, tituloCurso, toggleModal, setToggleModal}) => {

    const navigate = useNavigate()
    const [capacitacion,setCapacitacion] = useState(tituloCurso)
    const [nombre,setNombre] = useState("")
    const [email,setEmail] = useState("")
    const [area,setArea] = useState("")
    const [telefono,setTelefono] = useState("")


    function sendEmail(e) {
        const fecha = format(new Date(), 'yyyy-MM-dd')
        e.preventDefault();
        emailjs.sendForm('FISP_MasInfo', 'template_mas_info_curso', e.target, '5Lgvry98-uilVRBhz')
            .then((result) => {
                Axios.post("https://srv452353.hstgr.cloud/registrarformulario",{
                    capacitacion: capacitacion,
                    nombre: nombre,
                    email: email,
                    area: area,
                    telefono: telefono,
                    fecha: fecha
                }).then((result)=>{
                    console.log(result)
                    console.log("Se envio con exito")
                }).catch((e)=>{
                    console.log("Error")
                    console.log(e)
                })
                toast.success('El correo fue enviado con éxito!');
            }, (error) => {
                console.log(error.text);
                toast.error('No se pudo enviar el correo.');
            });

        e.target.reset();
        navigate(`/educativo/pre-inscripcion/${linkCurso}`);
    }

    return (
        <div className={`${toggleModal ? 'modal-show' : ''} modal-custom`} >
            <ToastContainer />
            <div className="modal-form">
                <svg onClick={()=>{setToggleModal(false)}} width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <path d="M18 6l-12 12" />
                    <path d="M6 6l12 12" />
                </svg>
                <div className="d-flex flex-column p-sm-5 px-1 py-4">
                    <form onSubmit={(e)=>{
                            sendEmail(e)
                        }}>
                        <h2 className='poppins fs-5 d-flex flex-column'>
                            Quiero más información sobre el precio y forma de pago de: 
                            <span>{tituloCurso}</span>
                        </h2>
                        <div className="d-flex">
                            <input type="text" readOnly required value={tituloCurso} className="form-input bg-light d-none" id="curso" name="curso" />
                        </div>
                        <div className="mb-4 d-flex mt-4">
                            <input type="text" onChange={(e)=>{setNombre(e.target.value)}} required placeholder='Nombre' className="form-input bg-light" id="nombre" name="nombre" />
                        </div>
                        <div className="mb-4 d-flex">
                            <input type="email" onChange={(e)=>{setEmail(e.target.value)}} required placeholder='Email' className="form-input bg-light" id="email" name="email" />
                        </div>
                        <div className="mb-5 d-flex gap-3 overflow-hidden">
                            <input type="tel" onChange={(e)=>{setArea(e.target.value)}} autoComplete='off' required placeholder='+54' className="col-sm-2 col-3 form-input bg-light" id="codigo" name="codigo" />
                            <input type="tel" onChange={(e)=>{setTelefono(e.target.value)}} required placeholder='Telefono' className="col-sm-10 col-9 form-input bg-light" id="telefono" name="telefono" />
                        </div>
                        <button type="submit" className="btn btn-dark w-100 mt-4">Enviar mensaje</button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Modal